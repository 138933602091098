import { useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';

import DashboardLayout from 'LayoutContainers/DashboardLayout';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';

import Footer from 'comps/Footer';
import MDTypography from 'components/MDTypography';
import { Avatar, Stack } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { STALE_TIME } from 'config';
import FriendsList from './friendsList';
import {
  fetchUserProfile, getCurrentUser, sendFriendRequest, approveFriend, removeFriend,
} from '../../api';
import { Context } from '../../store/Store';
import AvatarUpload from './avatarUpload';
import { stringAvatar } from '../../utils';
import MDButton from '../../components/MDButton';
import Notifications from './notifications';
import Header from '../header';

function Profile() {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(Context);
  const { user } = state;
  const { id } = useParams();
  const queryClient = useQueryClient();

  const filePond = useRef(null);

  const userId = id || user.id;
  const { data } = useQuery(['profile', userId], () => fetchUserProfile(userId), {
    staleTime: STALE_TIME.ONE_HOUR,
  });
  const userProfile = data?.data;
  const { user: profileUser = {}, userStatistics, friends } = userProfile || {};
  const {
    pointsInChallenge, hoursInChallenge, avgPointsPerDayInChallenge, userPosition, distanceInChallenge, teamCurrentPosition,
  } = userStatistics || {};
  const {
    id: profileId, fullName, avatar, team = {}, competeAsIndividual,
  } = profileUser;
  const isMyProfile = user.id === profileId;

  const doSendFriendRequest = async () => {
    await sendFriendRequest(profileId, user.id);
    await queryClient.invalidateQueries({ queryKey: ['profile'] });
  };

  const doRemoveFriend = async (friendId) => {
    await removeFriend(user.id, friendId);
    await queryClient.invalidateQueries({ queryKey: ['profile'] });
  };

  const doApproveFriend = async (friendId) => {
    await approveFriend(user.id, friendId);
    await queryClient.invalidateQueries({ queryKey: ['profile'] });
  };

  const updateUser = async () => {
    await getCurrentUser()
      .then((res) => {
        dispatch({ type: 'SET_USER', payload: res.data });
      });
  };

  const renderAddFriendButton = () => {
    if (isMyProfile) {
      return null;
    }
    const friend = friends?.find((f) => f.id === user.id);
    if (friend && friend.approvedFriend) {
      return <FavoriteIcon color="error" sx={{ fontSize: 50 }} />;
    }
    if (friend && !friend.approvedFriend) {
      return <MDButton variant="outlined" disabled color="info">{t('profile.friend.action.waiting')}</MDButton>;
    }
    return (
      <MDButton variant="outlined" color="success" onClick={doSendFriendRequest}>{t('profile.friend.action.add')}</MDButton>
    );
  };

  const renderItems = (items) => Object.entries(items).map(([key, value]) => (
    <MDBox key={key} display="flex" py={1} pr={2}>
      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
        {t(key)}
        : &nbsp;
      </MDTypography>
      <MDTypography variant="button" fontWeight="regular" color="text" ml="auto">
            &nbsp;
        {value}
      </MDTypography>
    </MDBox>
  ));

  return (
    <DashboardLayout>
      <Header challenge={user.team.challenge} />
      <MDBox mb={2}>
        <Card sx={{ py: 2, px: 2 }}>
          <Grid container spacing={2} alignItems="center" justifyContent="space-between">
            <Grid item>
              <Stack direction="row" justifyContent="center" alignItems="center" spacing={3}>
                {isMyProfile ? (
                  <AvatarUpload filePond={filePond} imageUrl={avatar} updateUser={updateUser} />
                ) : (
                  <Avatar {...stringAvatar(fullName, 'xl')} src={avatar} />
                )}
                <MDBox height="100%" mt={0.5} lineHeight={1}>
                  <MDTypography variant="h5" fontWeight="medium">
                    {fullName}
                  </MDTypography>
                  <MDTypography variant="button" color="text" fontWeight="regular">
                    { competeAsIndividual ? t('general.individual_team') : team.teamName }
                    {' '}
                    { team.teamUnitName != null ? `/${team.teamUnitName}` : ''}
                  </MDTypography>
                </MDBox>
              </Stack>
            </Grid>
            <Grid item>
              {renderAddFriendButton()}
            </Grid>
          </Grid>

          <MDBox mt={5} mb={3}>

            <Grid container spacing={1}>
              <Grid item xs={12} md={6} xl={3}>
                <Card sx={{ boxShadow: 'none' }}>
                  <MDBox pt={2} px={2}>
                    <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                      {t('profile.statistics')}
                    </MDTypography>
                  </MDBox>
                  <MDBox p={2}>
                    {renderItems({
                      'profile.challengePoints': pointsInChallenge,
                      'profile.avgPoints': avgPointsPerDayInChallenge,
                      'profile.myPosition': userPosition,
                      'profile.teamPosition': competeAsIndividual ? 'N/A' : teamCurrentPosition,
                      'profile.distance': `${(distanceInChallenge / 1000).toFixed(1)} km`,
                      'profile.hours': hoursInChallenge,
                    })}
                  </MDBox>
                </Card>
              </Grid>

              <Grid item xs={12} md={6} xl={4}>
                {friends
                  && (
                  <FriendsList
                    friends={friends}
                    shadow={false}
                    isMyProfile={isMyProfile}
                    removeFriend={doRemoveFriend}
                    approveFriend={doApproveFriend}
                  />
                  )}
              </Grid>

              {isMyProfile
                ? (
                  <Grid item xs={12} md={6} xl={5}>
                    <Notifications user={user} />
                  </Grid>
                )
                : null}
            </Grid>
          </MDBox>

        </Card>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default Profile;
