import { useReducer, useState } from 'react';
import MDBox from 'components/MDBox';

import { useQuery } from 'react-query';
import { Card } from '@mui/material';
import PropTypes from 'prop-types';
import { STALE_TIME } from 'config';
import { isAfter } from 'date-fns';
import Header from './header';
// eslint-disable-next-line import/no-cycle
import DataTable from './DataTable/DataTable';
import {
  TEAM_UNITS_COLUMNS, TEAMS_COLUMNS, TEAMS_WITH_UNITS_COLUMNS, USERS_COLUMNS,
} from './DataTable/columns';
import { fetchLeaderboard, exportLeaderboard } from '../../../api';

const getStageIndex = (challenge) => {
  if (!challenge.challengeStages.length > 1) {
    return 0;
  }
  let stageIndex = 1;
  const now = new Date();

  challenge.challengeStages.forEach((stage, index) => {
    if (isAfter(now, new Date(stage.startTime))) {
      stageIndex = index + 1;
    }
  });
  return stageIndex;
};

const initialState = {
  queryPageIndex: 0,
  queryPageSize: 10,
  totalCount: 0,
  queryPageFilter: '',
  queryPageSortBy: [],
  selectedTab: 'teams',
};

export const PAGE_CHANGED = 'PAGE_CHANGED';
export const PAGE_SIZE_CHANGED = 'PAGE_SIZE_CHANGED';
export const PAGE_SORT_CHANGED = 'PAGE_SORT_CHANGED';
export const PAGE_FILTER_CHANGED = 'PAGE_FILTER_CHANGED';
export const TOTAL_COUNT_CHANGED = 'TOTAL_COUNT_CHANGED';
export const TAB_CHANGED = 'TAB_CHANGED';
export const STAGE_CHANGED = 'STAGE_CHANGED';

const reducer = (state, { type, payload }) => {
  switch (type) {
    case PAGE_CHANGED:
      return {
        ...state,
        queryPageIndex: payload,
      };
    case PAGE_SIZE_CHANGED:
      return {
        ...state,
        queryPageSize: payload,
      };
    case PAGE_SORT_CHANGED:
      return {
        ...state,
        queryPageSortBy: payload,
      };
    case PAGE_FILTER_CHANGED:
      return {
        ...state,
        queryPageFilter: payload,
      };
    case TOTAL_COUNT_CHANGED:
      return {
        ...state,
        totalCount: payload,
      };
    case TAB_CHANGED:
      return {
        ...state,
        queryPageIndex: 0,
        queryPageSize: 10,
        totalCount: 0,
        queryPageFilter: '',
        queryPageSortBy: [],
        selectedTab: payload,
      };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

const fetchScoreboard = async (user, page, pageSize, pageFilter, pageSortBy, selectedTab, selectedStage) => {
  let paramStr = '';
  let pageNo = page;
  if (pageFilter && pageFilter.trim().length > 1) {
    paramStr = `&search=${pageFilter}`;
    pageNo = 0;
  }

  if (pageSortBy.length > 0) {
    const sortParams = pageSortBy[0];
    const sortByDir = sortParams.desc ? 'desc' : 'asc';
    paramStr = `${paramStr}&sortBy=${sortParams.id}&sortDir=${sortByDir}`;
  }

  // Team leaderboard
  let url = '/leaderboards/team';
  if (selectedTab === 'team_units') {
    // Team unit leaderboard
    url = '/leaderboards/teamUnit';
  }
  if (selectedTab === 'users') {
    // User leaderboard
    url = '/leaderboards/user';
  }
  if (selectedTab === 'my_team') {
    // My team leaderboard
    url = '/leaderboards/myTeam';
  }
  url += `?pageNo=${pageNo}&pageSize=${pageSize}${paramStr}&stage=${selectedStage}`;
  try {
    const response = await fetchLeaderboard(url);
    const results = response.data.data;
    return {
      results,
      count: response.data.metaData.totalCount,
      maxPoints: response.data.metaData.maxPoints,
    };
  } catch (e) {
    throw new Error(`API error:${e?.message}`);
  }
};
const getColumns = (selectedTab, unitChallenge) => {
  if (selectedTab === 'users' || selectedTab === 'my_team') {
    return USERS_COLUMNS;
  }
  if (selectedTab === 'team_units') {
    return TEAM_UNITS_COLUMNS;
  }
  return unitChallenge ? TEAMS_WITH_UNITS_COLUMNS : TEAMS_COLUMNS;
};

function Scoreboard({ user }) {
  const [stage, setStage] = useState(getStageIndex(user.team.challenge));
  const [filter, setFilter] = useState('');

  const [{
    queryPageIndex, queryPageSize, totalCount, queryPageFilter, queryPageSortBy, selectedTab,
  }, dispatch] = useReducer(reducer, initialState);

  const {
    data,
  } = useQuery(
    ['scoreboard', user.id, queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy, selectedTab, stage],
    () => fetchScoreboard(user, queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy, selectedTab, stage),
    {
      keepPreviousData: true,
      staleTime: STALE_TIME.TWO_MINUTES,
    },
  );

  const doExport = async () => {
    const response = await exportLeaderboard(stage);
    const url = window.URL.createObjectURL(response.data);
    const a = document.createElement('a');
    a.href = url;
    a.download = `workouts_challenge_${user.team.challenge.id}.xlsx`;
    document.body.appendChild(a);
    a.click();
    a.remove();
    return { data: 'ok' };
  };

  const columns = getColumns(selectedTab, user.team.challenge.unitChallenge);

  const setTab = (val) => {
    dispatch({ type: TAB_CHANGED, payload: val });
    setFilter('');
  };

  const onStageChange = (val) => {
    setStage(val);
    setFilter('');
  };

  return (
    <Card>
      <MDBox pt={3} pl={3} pr={3} pb={2}>
        <Header
          setTab={setTab}
          changeStage={onStageChange}
          stage={stage}
          user={user}
        />
        <MDBox>
          <DataTable
            columns={columns}
            data={data}
            dispatch={dispatch}
            queryPageIndex={queryPageIndex}
            queryPageSize={queryPageSize}
            queryPageSortBy={queryPageSortBy}
            totalCount={totalCount}
            filter={filter}
            setFilter={setFilter}
            selectedTab={selectedTab}
            stage={stage}
            maxPoints={data?.maxPoints}
            minTeamSize={user.team.challenge.minTeamSize}
            onExport={doExport}
          />
        </MDBox>
      </MDBox>
    </Card>
  );
}

Scoreboard.propTypes = {
  user: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
  ).isRequired,
};

export default Scoreboard;
