import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import './avatarUpload.css';
import { deleteAvatar } from '../../api';

// Register filepond the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginImageTransform,
  FilePondPluginImageResize,
  FilePondPluginFileValidateSize,
  FilePondPluginImageCrop,
);

function AvatarUpload({ filePond, imageUrl, updateUser }) {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (imageUrl) {
      const myFiles = [{
        source: `${imageUrl}?t=${Date.now()}`,
        options: {
          type: 'local',
        },
      }];
      setFiles(myFiles);
    }
  }, [imageUrl]);

  return (
    <div className="avatar-upload">
      <FilePond
        ref={filePond}
        files={files}
        onupdatefiles={setFiles}
        onremovefile={updateUser}
        onprocessfile={updateUser}
        allowMultiple={false}
        maxFiles={1}
        acceptedFileTypes="image/jpeg, image/png, image/gif, image/jpg"
        imageResizeMode="cover"
        imagePreviewHeight={150}
        imageCropAspectRatio="1:1"
        imageResizeTargetWidth={200}
        stylePanelLayout="compact circle"
        styleLoadIndicatorPosition="center bottom"
        styleButtonRemoveItemPosition="center bottom"
        server={{
          url: '/api/fileUpload/avatar',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
          load: (uniqueFileId, load, error) => {
            fetch(uniqueFileId)
              .then((res) => res.blob())
              .then(load)
              .catch(error);
          },
          revert: (uniqueFileId, load, error) => {
            const filename = uniqueFileId.substring(uniqueFileId.lastIndexOf('/') + 1);
            deleteAvatar(filename)
              .then(load)
              .catch(error);
          },
          remove: (source, load, error) => {
            const filename = source.substring(source.lastIndexOf('/') + 1);
            deleteAvatar(filename)
              .then(load)
              .catch(error);
          },
        }}
        name="file"
        labelIdle='Drag & Drop your file or <span class="filepond--label-action">Browse</span>'
        credits={false}
      />
    </div>
  );
}

AvatarUpload.defaultProps = {
  imageUrl: null,
};

AvatarUpload.propTypes = {
  filePond: PropTypes.oneOfType([PropTypes.object]).isRequired,
  imageUrl: PropTypes.string,
  updateUser: PropTypes.func.isRequired,
};

export default AvatarUpload;
